import React, {useLayoutEffect} from "react";
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { HostedSession } from "src/domain/HostedSession";

import "src/App.css";

import { useTranslation } from 'react-i18next';
import formatCurrency from "src/domain/CurrencyFormatter";

function BackButton({cancelUrl}: {cancelUrl: string | undefined}) {
    const navigate = useNavigate();
    const hideBackButton = cancelUrl === null || cancelUrl === undefined || cancelUrl === '';

    if (hideBackButton) {
        return null
    }

    return (
        <div id="backButton"  style={{minHeight: "30px", display: "flex", alignItems: "center", float:"left", marginRight: "12px", cursor: "pointer"}} onClick={() => window.location.href = cancelUrl }>
            <svg width="12" height="12" viewBox="0 0 16 16"><path d="M3.417 7H15a1 1 0 0 1 0 2H3.417l4.591 4.591a1 1 0 0 1-1.415 1.416l-6.3-6.3a1 1 0 0 1 0-1.414l6.3-6.3A1 1 0 0 1 8.008 2.41z" fill-rule="evenodd"></path></svg>
        </div>
    );
}

export default function CheckoutSummary({hostedSession} : {hostedSession : HostedSession}) {
    const { t } = useTranslation();

    const logo = hostedSession.presentation_settings.assets.logo.indexOf('://') > 0 ?
    hostedSession.presentation_settings.assets.logo : require(`src/assets/${hostedSession.presentation_settings.assets.logo}`);

    useLayoutEffect( () => {
        if(hostedSession === undefined){
            return;
        }

        loadFavicon(hostedSession);
        setBackgroundColor(hostedSession);
    }, [hostedSession])

    const loadFavicon = (hostedSession : HostedSession) => {
        const link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        document.head.appendChild(link);

        if (hostedSession.presentation_settings.assets.favicon.indexOf("://") > 0) {
            (link as HTMLLinkElement).href = hostedSession.presentation_settings.assets.favicon;
        } else {
            (link as HTMLLinkElement).href = `/${hostedSession.presentation_settings.assets.favicon}-32x32.png`;
        }
    }

    const setBackgroundColor = (hostedSession: HostedSession) => {
        document.body.style.backgroundColor = hostedSession.presentation_settings.branding.background_color;
    }

    if (hostedSession === null || hostedSession === undefined) {
        return <p>Loading..</p>;
    }

    return (<>
                <div style={{maxHeight: "30px", display: "flex", alignItems: "center", marginBottom: "30px"}}>
                    <BackButton cancelUrl={hostedSession?.cancel_url} />
                    <div id="logo" style={{float: "left", minWidth: "100px", maxHeight: "30px"}}>
                        <img className="branding-logo" width="auto" height="auto" style={{maxHeight: "30px"}} src={logo} alt="brandingLogo"></img>
                    </div>
                    <div id="backLabel" style={{float: "left", fontWeight:500, fontSize: "14px"}}>
                        <span>{t("back")}</span>
                    </div>
                </div>
                <div className="payment-info">
                    <div hidden={hostedSession?.items.length > 1}>
                        <Grid container spacing={1} className="xs-centered" >
                            <Grid item xs={12}>
                                <span style={{color: "hsla(0,0%,10%,.6)", fontWeight: 500, fontSize: "16px"}}>{hostedSession.items[0].name}</span>
                            </Grid>
                            <Grid item xs={12}>
                                <span style={{fontWeight: 500, fontSize: "36px", letterSpacing: "-.03rem"}}>{formatCurrency(hostedSession.locale, hostedSession.currency, hostedSession.items[0].unit_price)}</span>
                            </Grid>
                            <Grid item xs={12} hidden={!hostedSession.items[0].additional_info || hostedSession.items[0].additional_info === ""}>
                                <span style={{color: "hsla(0,0%,10%,.6)", fontWeight: 500, fontSize: "14px"}}>{hostedSession.items[0].additional_info}</span>
                            </Grid>
                        </Grid>
                    </div>
                    <div hidden={hostedSession?.items.length === 1}>
                        <Grid container spacing={1} >
                            <Grid item xs={12} className="xs-centered">
                                <span style={{color: "hsla(0,0%,10%,.6)", fontWeight: 500, fontSize: "16px"}}>{t("totalAmountToBePaid")}</span>
                            </Grid>
                            <Grid item xs={12} className="xs-centered">
                                <span style={{fontWeight: 500, fontSize: "36px", letterSpacing: "-.03rem"}} data-testid="total">{formatCurrency(hostedSession.locale, hostedSession.currency, hostedSession.amount)}</span>
                            </Grid>
                            <div style={{marginTop: "25px", width: "100%", marginLeft: "8px"}}>
                                {hostedSession?.items.map(i => (
                                    <Grid key={i.product_id} container spacing={1} style={{marginTop: "12px"}} data-testid="item-container">
                                        <Grid item xs={8}>
                                            <span style={{color: "rgba(26, 26, 26, 0.9)", fontWeight: 500, fontSize: "14px"}} data-testid="product-name">{i.name}</span>
                                        </Grid>
                                        <Grid item xs={3} style={{textAlign: "right"}}>
                                            <span style={{color: "rgba(26, 26, 26, 0.9)", font:"Segoe UI Semibold", fontWeight: 500, fontSize: "14px", letterSpacing: "-.03rem"}} data-testid="product-price">{formatCurrency(hostedSession.locale, hostedSession.currency, (i.quantity*i.unit_price))}</span>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={8} style={{marginTop: "-8px"}}>
                                            <span style={{color: "hsla(0,0%,10%,.5)", fontWeight: 400, fontSize: "12px"}} data-testid="product-quantity">{t("qty")} {i.quantity}</span>
                                        </Grid>
                                        <Grid item xs={3} hidden={i.quantity === 1} style={{textAlign: "right", marginTop: "-8px"}}>
                                            <span style={{color: "hsla(0,0%,10%,.5)", fontWeight: 400, fontSize: "12px", letterSpacing: "-.03rem"}}>{formatCurrency(hostedSession.locale, hostedSession.currency, i.unit_price)} {t("each")}</span>
                                        </Grid>
                                        <Grid item xs={8} hidden={!i.additional_info || i.additional_info === ""} style={{marginTop: "-8px"}}>
                                            <span style={{color: "hsla(0,0%,10%,.5)", fontWeight: 400, fontSize: "12px"}}>{i.additional_info}</span>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                    </Grid>
                                ))}
                            </div>
                        </Grid>
                    </div>
                    
                </div>
            </>
    );
}
