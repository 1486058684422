// Taken from here: https://github.com/julienetie/detect-browser/blob/main/detect-browser.js

const navigatorErrorMessage = 'Could not find `userAgent` or `userAgentData` window.navigator properties to set `os`, `browser` and `version`'
const removeExcessMozillaAndVersion = /^mozilla\/\d\.\d\W/
const browserPattern = /(\w+)\/(\d+\.\d+(?:\.\d+)?(?:\.\d+)?)/g
const engineAndVersionPattern = /^(ver|cri|gec)/
const brandList = ['chrome', 'opera', 'safari', 'edge', 'firefox']
const unknown = 'Unknown'
const empty = ''
const { isArray } = Array
let userAgentData = window.navigator.userAgentData
let userAgent = window.navigator.userAgent
let screenSize = ''

const mobiles = {
    iphone: /iphone/,
    ipad: /ipad|macintosh/,
    android: /android/
}

const desktops = {
    windows: /win/,
    mac: /macintosh/,
    linux: /linux/
}

const detectPlatform = () => {
    if (window.screen.width) {
        const width = (window.screen.width) ? window.screen.width : '';
        const height = (window.screen.height) ? window.screen.height : '';
        screenSize = '' + width + " x " + height;
    }

    if (userAgent) {
        const ua = userAgent.toLowerCase().replace(removeExcessMozillaAndVersion, empty)

        // Determine the operating system.
        const mobileOS = Object.keys(mobiles).find(os => mobiles[os].test(ua) && window.navigator.maxTouchPoints >= 1)
        const desktopOS = Object.keys(desktops).find(os => desktops[os].test(ua))
        const os = mobileOS || desktopOS

        // Extract browser and version information.
        const browserTest = ua.match(browserPattern)
        const versionRegex = /version\/(\d+(\.\d+)*)/
        const safariVersion = ua.match(versionRegex)
        const saVersion = isArray(safariVersion) ? safariVersion[1] : null
        const browserOffset = browserTest && (browserTest.length > 2 && !(engineAndVersionPattern.test(browserTest[1])) ? 1 : 0)
        const browserResult = browserTest && browserTest[browserTest.length - 1 - (browserOffset || 0)].split('/')
        let browser = browserResult && browserResult[0]
        let browserVersion = saVersion ? saVersion : browserResult && browserResult[1]

        // Check specifically for Edge
        if (ua.includes('edg/')) {
            const edgeMatch = ua.match(/edg\/(\d+\.\d+)/);
            browser = 'edge';
            browserVersion = edgeMatch && edgeMatch[1];
        }

        return { os, browser, browserVersion, screenSize }
    }

    if (userAgentData) {
        const os = userAgentData.platform.toLowerCase()
        let platformData = {}

        // Extract platform brand and version information.
        for (const agentBrand of userAgentData.brands) {
            const agentBrandEntry = agentBrand.brand.toLowerCase()
            const foundBrand = brandList.find(brand => { //eslint-disable-line
                if (agentBrandEntry.includes(brand)) {
                    return brand
                }
                return undefined
            })

            if (foundBrand) {
                platformData = { browser: foundBrand, browserVersion: agentBrand.version }
                break
            }
        }
        const brandVersionData = platformData || { browser: unknown, browserVersion: unknown }
        return { os, ...brandVersionData, screenSize }
    }

    return {
        // Ignore the strikethrough. This is just a fallback
        os: navigator.platform || unknown,
        browser: unknown,
        browserVersion: unknown,
        screenSize : unknown
    }
}

export default detectPlatform