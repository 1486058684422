import * as React from 'react'
import Typography from "@mui/material/Typography"
import logger from "src/logger";

type Props = {
    children: React.ReactNode;
}

type State = {
    hasError: boolean
}

export default class ErrorBoundary extends React.Component< Props, State > {
    constructor(props: Props ) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidMount() {
        window.onerror = (message, source, lineno, colno, error) => {
            logger.child({
                error: error ? error.message : JSON.stringify(error)
            }).error("an unexpected error occurred");
        };

        window.addEventListener('unhandledrejection', this.logUnhandledRejection);
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.logUnhandledRejection);
    }

    logUnhandledRejection = (event: PromiseRejectionEvent) => {
        logger.child({
            error: event.reason
        }).error("an unhandled rejection occurred");
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({hasError: true});

        logger.child({
            error: error instanceof Error ? error.message : JSON.stringify(error),
            errorInfo: JSON.stringify(errorInfo)
        }).error("an unexpected error occurred");
    }

    render() {
        if ( this.state.hasError ) {
            return (
                <div>
                    <Typography variant={'body1'}>
                        Oops! Something went really wrong
                    </Typography>
                </div>
            );
        }

        return <>{ this.props.children }</>;
    }
}
