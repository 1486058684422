import Grid from '@mui/material/Grid';
import { Routing } from './components/Navigation';
import { BrowserRouter as Router } from 'react-router-dom';
import theme from './theme/paywallTheme';
import PseudoLocalization from "./components/PseudoLocalization";
import { ThemeProvider } from '@mui/system';
import logger from "src/logger";
import Bowser from "bowser";

function App() {
    const sessionDetails = Bowser.getParser(window.navigator.userAgent).getResult();
    logger.child(sessionDetails).info("paywall session started");

    return (
        <PseudoLocalization>
            <Router>
                <ThemeProvider theme={theme}>
                    <Grid container>
                        <Grid item style={{ flex: 1, width: '100%' }}>
                            <Routing />
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </Router>
        </PseudoLocalization>
    );
}

export default App;