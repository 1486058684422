import config from "src/config";

const metricsEndpoint = config.apis.tokenMediatorUrl + "/metrics-frontend";

const metricTypes = {
    payment_page_initialization_failed: "payment_page_initialization_failed",
    payment_component_initialization_failed: "payment_component_initialization_failed",
    setup_page_initialization_failed: "setup_page_initialization_failed",
    payment_completion_failed: "payment_completion_failed"
} as const;

export function paymentPageInitializationFailed() {
    sendMetric(metricTypes.payment_page_initialization_failed)
}

export function paymentComponentInitializationFailed() {
    sendMetric(metricTypes.payment_component_initialization_failed)
}

export function setupPageInitializationFailed() {
    sendMetric(metricTypes.setup_page_initialization_failed)
}

export function paymentCompletionFailed(){
    sendMetric(metricTypes.payment_completion_failed)
}

function sendMetric(metricId: string) {
    try {
        const metric = {
            metric_id : metricId
        }

        if (navigator.sendBeacon){
            navigator.sendBeacon(
                metricsEndpoint,
                JSON.stringify(metric)
            );
            return;
        }

        fallbackToXHR(JSON.stringify(metricId));
    } catch (error) {}
}

const fallbackToXHR = (payload) => {
    try {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', metricsEndpoint, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(payload);
    } catch (error) {}
};