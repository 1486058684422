import {useLayoutEffect, useMemo, useState} from "react";
import { HostedSession } from "src/domain/HostedSession";
import {
    useStripe,
    Elements,
    useElements,
    PaymentElement
} from "@stripe/react-stripe-js";
import "src/App.css";
import config from "src/config";
import { Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmRequest, ConfirmResponse } from "../workers/types";
import { Nullable } from "src/workers/types";
import { dispatch } from "src/workers/common-worker";
import { Button, CircularProgress, Grid } from "@mui/material";
import { ExistingInstrument } from "./ExistingInstrument";
import logger from "src/logger";

interface ICheckoutStripeProps {
    options : StripeElementsOptions; 
    stripePromise: Promise<Stripe | null> | null;
    hostedSession : HostedSession;
}

export default function CheckoutFormStripe({stripeFormData} :{stripeFormData: ICheckoutStripeProps}) {
    return (
        <Elements options={stripeFormData.options} stripe={stripeFormData.stripePromise}>
            <StripeForm {...stripeFormData.hostedSession}></StripeForm>
        </Elements>
    );
}

function StripeForm(hostedSession : HostedSession) {
    const { t, i18n } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const { param } = useParams();

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isPaying, setIsPaying] = useState<boolean>(false);

    const [usingExistingCard, setShowCard] = useState<boolean>(hostedSession?.instrument !== undefined);

    const worker: Worker = useMemo(
        () => new Worker(new URL("../workers/confirm-worker.ts", import.meta.url)),
        []
    );

    const paymentsServiceUrl: string =  config.apis.paymentServiceUrl
    const tokenMediatorUrl: string =  config.apis.tokenMediatorUrl

    const logo = hostedSession.presentation_settings.assets.logo.indexOf('://') > 0 ?
        hostedSession.presentation_settings.assets.logo : require(`src/assets/${hostedSession.presentation_settings.assets.logo}`);

    let _logger = logger.child({
        hostedSessionID: param
    })

    useLayoutEffect( () => {
        if (!stripe) {
            return;
        }

        loadFavicon(hostedSession);
        setBackgroundColor(hostedSession);
        _logger.info("stripe component initialized");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe, hostedSession])

    const handleSubmit = () => {
        if (isPaying) {
            return;
        }

        setIsPaying(true);

        if (usingExistingCard) {
            if (Worker) {
                const hsID: string = param || "";
                const instrumentID: string = hostedSession?.instrument?.id || "";
                const clientSecret: string = hostedSession?.client_secret || "";

                const request: ConfirmRequest = {
                    tokenMediatorUrl: tokenMediatorUrl,
                    paymentsServiceUrl: paymentsServiceUrl,
                    hsID: hsID,
                    instrumentID: instrumentID,
                    clientSecret: clientSecret,
                    testMode: hostedSession?.test_mode,
                    tenantId: hostedSession?.tenant_id
                };

                dispatch<ConfirmRequest, ConfirmResponse>(worker, request)
                    .then((data: Nullable<ConfirmResponse>) => handleConfirmResponse(data))
                    .catch((error) => {
                        _logger.child({
                            error: error instanceof Error ? error.message : JSON.stringify(error)
                        }).error("an error occurred confirming a payment with existing instrument");
                        setIsPaying(false);
                    });
            }
        } else {
            let redirectUrl = window.location.protocol + "//" + window.location.host + "/complete?hostedSessionId=" + param +
                "&payment_intent_client_secret=" + hostedSession?.client_secret + "&t_id=" + encodeURIComponent(hostedSession?.tenant_id);

            if (hostedSession?.test_mode) {
                redirectUrl = redirectUrl + "&testMode=" + hostedSession?.test_mode
            }

            confirmStripePayment(redirectUrl);
        }
    };

    if (hostedSession === null || hostedSession === undefined) {
        return <p>Loading..</p>;
    }

    const showCardChanged = (state: boolean) => {
        setShowCard(state);
    }

    const loadFavicon = (hostedSession : HostedSession) => {
        const link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        document.head.appendChild(link);

        if (hostedSession.presentation_settings.assets.favicon.indexOf("://") > 0) {
            (link as HTMLLinkElement).href = hostedSession.presentation_settings.assets.favicon;
        } else {
            (link as HTMLLinkElement).href = `/${hostedSession.presentation_settings.assets.favicon}-32x32.png`;
        }
    }

    const setBackgroundColor = (hostedSession: HostedSession) => {
        document.body.style.backgroundColor = hostedSession.presentation_settings.branding.background_color;
    }

    const handleConfirmResponse = (data) => {
        if (!data.intent_status) {
            _logger.error("an error occurred confirming a payment with existing instrument")
        }

        if (data.intent_status === "authorized") {
            _logger.info("payment authorization successful");
            window.location.replace(hostedSession.success_url);
        } else if (data.intent_status === "challenged") {
            _logger.info("3D Secure challenge initiated")
            window.location.replace(data.challenge_redirect_url);
        } else {
            _logger.warn("payment authorization failed")
            const errorMsg = t("errorMessage");
            setErrorMessage(errorMsg);
            setIsPaying(false)
        }
    }

    const confirmStripePayment = (redirectUrl) => {
        if (!stripe || !elements) {
            return
        }

        _logger.info("confirming payment");

        stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: redirectUrl,
            },
        }).then((value) => {
            // only reached if an error occurs, otherwise the success redirect page is called
            setIsPaying(false);

            if (value.error.type === "card_error" || value.error.type === "validation_error") {
                setErrorMessage(value.error?.message ?? '');
            } else {
                const errorMsg = t("errorMessage");
                setErrorMessage(errorMsg);
            }

            _logger.child({
                error: value.error.type,
                message: value.error.message ?? ''
            }).warn("an error occurred confirming stripe payment");

        }).catch((error) => {
            setIsPaying(false);
            _logger.error({
                error: error instanceof Error ? error.message : JSON.stringify(error)
            }, "an unexpected error occurred confirming stripe payment");
        });
    }

    return (
            <Grid item xs={12} sm={6} md={4}>
                <div hidden={!usingExistingCard}>
                    <ExistingInstrument instrument={hostedSession?.instrument} requestCardChange={showCardChanged}></ExistingInstrument>
                    <div hidden={errorMessage.length === 0} style={{ marginTop: '15px', color: '#dc2727'}}>{errorMessage}</div>
                </div>
                <div hidden={usingExistingCard}>
                    <div style={{width: "100%", textAlign: 'right'}} hidden={usingExistingCard || hostedSession?.instrument === undefined}>
                        <span className="clickable-text" onClick={(e) => setShowCard(true)}>
                            {t("keepUsing")} •••• {hostedSession?.instrument?.last4}
                        </span>
                    </div>
                    <PaymentElement id="payment-element"/>

                    <div hidden={errorMessage.length === 0} style={{ marginTop: '15px', color: '#dc2727'}}>{errorMessage}</div>
                </div>
                <div style={{ marginTop: "20px" }}>
                    <Button
                        onClick={handleSubmit}
                        color={"primary"}
                        fullWidth={true}
                        variant="contained"
                        style={{ opacity: isPaying ? 0.2 : 1, backgroundColor: hostedSession.presentation_settings.branding.button_color}}
                    >
                        <span hidden={isPaying}>{t("pay")}</span>
                        <div hidden={!isPaying}>
                            <CircularProgress size="1.75rem" style={{'color': 'white'}}/>
                        </div>
                    </Button>
                </div>
                <div className="allow-text" hidden={!usingExistingCard || true}>
                    {/* to be shown only when it makes sense for the card to be used in off session */}
                    {t("checkoutCardAdvice")}
                </div>
            </Grid>
    );
}