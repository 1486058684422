import FadeLoader from "react-spinners/FadeLoader";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import config from "src/config";
import { CompleteRequest } from "src/workers/types";
import { dispatch } from "src/workers/common-worker";
import logger from "src/logger";
import {paymentCompletionFailed} from "src/metrics";

export default function Complete() {
    const [searchParams] = useSearchParams();

    const worker: Worker = useMemo(
        () => new Worker(new URL("../workers/complete-worker.ts", import.meta.url)),
        []
    );

    const paymentsServiceUrl: string =  config.apis.paymentServiceUrl
    const tokenMediatorUrl: string =  config.apis.tokenMediatorUrl

    useEffect(() => {
        if (Worker) {
            const clientSecret = searchParams.get("payment_intent_client_secret") || "";
            const hsID = searchParams.get("hostedSessionId") || "";
            const testMode = Boolean(searchParams.get("testMode"));
            const tenantId = searchParams.get("t_id") || "";

            const request: CompleteRequest = {
                tokenMediatorUrl: tokenMediatorUrl,
                paymentsServiceUrl: paymentsServiceUrl,
                hsID: hsID,
                clientSecret: clientSecret,
                testMode: testMode,
                tenantId: tenantId
            };

            dispatch<CompleteRequest, any>(worker, request)
                .then((data) => {
                    if (!data.redirect_url) {
                        logger.child({
                            hostedSessionID: hsID
                        }).error("an error occurred completing a payment");

                        paymentCompletionFailed();
                        throw new Error("an error occurred completing a payment");
                    }
                    window.location.replace(data.redirect_url)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [worker, searchParams, paymentsServiceUrl, tokenMediatorUrl]);

    return <div className="lds-dual-ring"></div>;
}